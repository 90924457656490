import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ChangeDetectionStrategy,
  SimpleChange,
  SimpleChanges,
  ViewChild,
  TemplateRef,
  ChangeDetectorRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { Router } from '@angular/router';
import { EmptyError, Subscription, isEmpty } from 'rxjs';
import { Layer, marker, tileLayer } from 'leaflet';
import * as L from 'leaflet';
import { HttpService } from 'src/app/services/http.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatMenuTrigger } from '@angular/material/menu';
import { DialogService } from 'src/app/services/dialog.service';
import { DatePipe } from '@angular/common';
import { SharedService } from 'src/app/services/shared.service';

// import {OpenStreetMapProvider} from 'leaflet-geosearch';
declare var L: any;

@Component({
  selector: 'employee-tracker',
  templateUrl: './employee-tracker.component.html',
  styleUrls: ['./employee-tracker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeTrackerComponent implements OnInit, OnChanges, OnDestroy {
  projectBoundaryMarkers: Layer[] = [];
  empPositionMarkers: Layer[] = [];
  allMarkers: Layer[] = [];
  projectMapLists: any = [];
  locTimes = [];
  isDefault = true;
  locations: any = [];
  mapZoom = 14;
  mapSub: Subscription[] = [];
  isDialogOpen = false;
  _calMaxDate = new Date();
  dateString = '';
  currentDate: Date;
  public dropedlat: any;
  public dropedlng: any;
  index: any;
  osmAttribution =
    'Map data © <a href="http://openstreetmap.org">OpenStreetMap</a> contributors,' +
    ' <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>';
  // Open Street Map definitions
  LAYER_OSM = tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: 24,
    attribution: this.osmAttribution,
  });
  options = {
    layers: [this.LAYER_OSM],
    zoom: this.mapZoom,
  };
  mapCenter: any;
  _emp: any;
  _orgId: string;
  map: any;
  currentLocation: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  dataobjects: any;
  PingData: any[] = [];
  showAddSignButtons: boolean = false;
  showDeleteButton: boolean = false;
  show: boolean = true;
  nodata: boolean = false;
  actionType: string;
  CurrentIndex: any;
  collectionName: any;
  dt: any;
  @Input()
  set emp(val: any) {
    this._emp = val;
  }

  @Input() set orgId(val: string) {
    this._orgId = val;
  }
  dashboardLinkFields = [
    '_id',
    'eid',
    'emp.name',
    'emp_id',
    'name',
    'sign_in.t',
    'sign_out.t',
    'signin',
    'signout',
  ];
  defaultFields = [
    'text',
    'code',
    'mobile',
    'date',
    'time',
    'status',
    'dateTime',
    'duration',
  ];
  @ViewChild(MatMenuTrigger)
  contextMenu: MatMenuTrigger;
  // pingMenu: MatMenuTrigger;
  @ViewChild('attendanceAddDialog', { static: true })
  attendanceAddDialog: TemplateRef<any>;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private snackbar: SnackbarService,
    private http: HttpService,
    public helperService: HelperService,
    private authService: AuthService,
    private router: Router,
    private dialogService: DialogService,
    private cf: ChangeDetectorRef,
    private datePipe: DatePipe,
    private sharedService: SharedService
  ) {
    this.authService.selectedOrgId.subscribe((id) => {
      this.router.navigate(['/page/employee/list']);
    });
  }

  ngOnInit() {
    this.currentDate = new Date();
    this.dataobjects = JSON.parse(localStorage.getItem('lts_auth'));
    this.sharedService.geolocationDatafield$.subscribe(data => {
      debugger
      if (data != null) {
        this.checksignIn(data);
      }
    });
  }

  showCoordinates(e) {
    alert(e.latlng);
  }

  removelocal() {
    //removelocal function {{remove the localstorage if not available data from selected date }}
    localStorage.removeItem('_id');
    localStorage.removeItem('device_id');
    // sessionStorage.removeItem('geo_data');
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentItem: SimpleChange = changes.item;

    if (
      sessionStorage.getItem('geo_data') !== null &&
      sessionStorage.getItem('geo_data') !== undefined &&
      JSON.parse(sessionStorage.getItem('geo_data')).type !== 'CL'
    ) {
      this.OnIntMap();
    }

    // this.dateString = this.showLastSeen();
    if (this._emp) {
      this.getProjectBoundaries(this._emp['pid']);
      this.loadProjectBoundaries();
      this.loadEmployeeLastSeenInfo();
      if (this._emp['last_seen']) {
        //set the map centre point based on emp's last seen location
        this.mapCenter = new L.LatLng(
          this._emp['last_seen'].lat,
          this._emp['last_seen'].lng
        );
      } else {
        this.mapCenter = new L.LatLng(13.0116321, 80.2305767);
      }
      this.mapZoom = 15;

      this.getEmpPingLocations(this._emp['_id'], this.currentDate); //it set the new localstorage data  if page is loaded
      this.removelocal(); //Removed old localstorage then stored new localstorage data
      this.dateString = this.showLastSeen();
    }
  }

  OnIntMap() {
    if (
      sessionStorage.getItem('geo_data') !== null ||
      (sessionStorage.getItem('geo_data') !== undefined && false)
    ) {
      const geoData = JSON.parse(sessionStorage.getItem('geo_data'));
      if (geoData.dt) {
        this.currentDate = geoData.dt;
      } else {
        this.currentDate = geoData.date;
      }

      let sign_in: any;
      let sign_out: any;
      if (geoData.sign_in !== undefined || geoData.sign_out !== undefined) {
        if (
          geoData.sign_in?.p === undefined ||
          geoData.sign_out?.p === undefined
        ) {
        } else {
          sign_in = geoData.sign_in?.p; // Check if geoData.sign_in is defined before accessing its property
          sign_out = geoData.sign_out?.p; // Check if geoData.sign_out is defined before accessing its property
        }
      } else {
        const lastIndex = geoData.in?.length - 1;

        if (lastIndex >= 0) {
          const lastItem = geoData.in[lastIndex];
          sign_in = lastItem;
          sign_out = lastItem;
        }
      }

      if (sign_in !== undefined && sign_in.length >= 2) {
        this.loadCurrentDateIcon(geoData.sign_in);
        this.mapCenter = new L.LatLng(sign_in[0], sign_in[1]);
      } else if (sign_out !== undefined && sign_out.length >= 2) {
        this.loadCurrentDateIcon(geoData.sign_out);
        this.mapCenter = new L.LatLng(sign_out[0], sign_out[1]);
      }
    }
  }

  loadCurrentDateIcon(inlocation: any) {
    this.empPositionMarkers = [];

    this.addEmpLocMarker(
      inlocation.t,
      inlocation.p[0],
      inlocation.p[1],
      't',
      0,
      'a'
    );
    this.combineMarkers();
  }

  dateChange(date: any) {
    this.mapZoom = 13;
    this.checkSignInAvailable(date.value).then((result: any) => {
      if (result && result.data && result.data.length > 0) {
        this.getEmpPingLocations(this._emp['_id'], date.value);
        this.dateString = this.helperService.showFullDate(date.value);
      } else {
        // If no data available for the selected date, load only the last seen info
        this.loadEmployeeLastSeenInfo();
        this.dateString = this.showLastSeen();
        this.removelocal(); //remove the localstorage if not available data from selected date
      }
    });
  }

  async checkSignInAvailable(selectedDate) {
    //Prevent the icon
    const startDate = moment(selectedDate).startOf('day').format();
    const endDate = moment(selectedDate).endOf('day').format();
    let filter: any;
    if (this._emp) {
      filter = {
        emp_id: this._emp['_id'],
        date_column: 'dt',
        start_date: startDate,
        end_date: endDate,
      };

      const result: any = await this.http
        .getDocListBySearch('emp_attendance', filter)
        .toPromise();

      // const result: any = await this.http .getDocListBySearch('device_ping', filter).toPromise();

      return result;
    }
  }

  /**
   *
   * Project boundary related methods
   */
  // To get project geo fencing boundaries for specific emp's project
  getProjectBoundaries(pid: string) {
    this.mapSub.push(
      this.http
        .getDocListBySearch('project_map', { pid: pid, status: 'A' })
        .subscribe((result: any) => {
          if (result) {
            this.projectMapLists = result;
          }
        })
    );
  }

  loadProjectBoundaries() {
    if (this.projectMapLists?.length < 1) return;
    if (!_.isEmpty(this.projectMapLists.data)) {
      this.projectMapLists.forEach((map: any) => {
        var latlng: any;
        if (map['type'] == 'circle') {
          var layer = L.circle(map['boundary'], map['radius'], map['options']);
          // latlng = new L.LatLng(projectMap["cp_lat"],projectMap["cp_lat"])
        } else if (map['type'] == 'rectangle') {
          var layer = L.rectangle(map['boundary'], map['options']);
        } else if (map['type'] == 'polygon') {
          var layer = L.polygon(map['boundary'], map['options']);
        }
        this.projectBoundaryMarkers.push(layer);
      });
      this.mapCenter = new L.LatLng(
        this.projectMapLists[0]['cp_lat'],
        this.projectMapLists[0]['cp_lng']
      );
      this.mapZoom = this.projectMapLists[0]['zoom'];
    }
  }
  /**
   *
   *  Employee Position setting related methods
   *
   */
  loadEmployeeLastSeenInfo() {
    this.empPositionMarkers = [];
    var l = this._emp['last_seen'];
    if (l) {
      //by default load the emp location marker from emp collection
      this.addEmpLocMarker(l.dt, l.lat, l.lng, 'e', 0, 'a');
    }
    this.combineMarkers();
    //
  }

  async getEmpPingLocations(eid, startDate?, endDate?) {
    startDate = startDate
      ? moment(startDate).startOf('day').format()
      : moment().startOf('day').format();
    endDate = endDate
      ? moment(endDate).endOf('day').format()
      : moment(startDate).endOf('day').format();

    var filter = {
      emp_id: eid,
      dt: startDate
    };

    this.empPositionMarkers = [];
    this.locTimes = [];

    this.mapSub.push(
      this.http.getEmp_Attendance(filter).subscribe((result: any) => {

        if (!_.isEmpty(result['data'])) {

          let InSignData: any = result?.data[0]
            ? result?.data[0]['sign_in']
            : undefined;
          let OutSignData: any = result?.data[0]
            ? result?.data[0]['sign_out']
            : undefined;
          this.dt = result.data[0]?.['date'];
 
          localStorage.setItem('_id', JSON.stringify(result.data[0]?.['_id']))

          if (!_.isEmpty(InSignData) || !_.isEmpty(OutSignData)) {


            this.empPositionMarkers = [];

            let Data: any[] = [];

            if (!_.isEmpty(OutSignData)) {
              Data.push(OutSignData);

              this.addEmpLocMarker(
                OutSignData?.['t'],
                OutSignData?.['p'][0],
                OutSignData?.['p'][1],
                't',
                1,
                'a',
                2,
                OutSignData,
                OutSignData["index"]
              );
            }

            if (!_.isEmpty(InSignData)) {
              Data.push(InSignData);

              this.addEmpLocMarker(
                InSignData?.['t'],
                InSignData?.['p'][0],
                InSignData?.['p'][1],
                't',
                0,
                'a',
                0,

                InSignData,
                InSignData["index"]
              );
            }


            this.locations = Data;

          }


        }


        if (Array.isArray(result.data)) {
          this.http.getDocList('device').subscribe((result: any) => {
            let device_id: any

            result.forEach((element: any) => {

              if (element.emp_id == this._emp['_id'] && element.status == "A") {

                device_id = element._id
              } else {
                device_id = "app"
              }
              localStorage.setItem(
                'device_id',
                JSON.stringify(device_id))

            })

          })
        }




        this.combineMarkers();


      })

    )
  }

  iconData: any[];
  addEmpLocMarker(
    date: any,
    lat: any,
    lng: any,
    action: any,
    idx: number,
    type: any,
    length?: number,
    data?: any,
    index?: any
  ) {
    var icon = 'assets/images/self-position.png';
    var dt = this.helperService.showDateTime(date);
    var draggable = false; // Set draggable to false by default

    if (action == 't') {
      if (idx == 0) {
        icon = 'assets/images/start-position.png';
        if (this.dataobjects?.role > 1) {
          draggable = true;
        }
      } else if (length && idx + 1 == length) {
        icon = 'assets/images/logout.png';

        if (this.dataobjects?.role > 1) {
          draggable = true;
        }
      } else {
        icon = 'assets/images/location.png';
        if (this.dataobjects?.role > 1) {
          draggable = true;
        }
      }
      dt = this.helperService.showTime(date);
    } else if (action == 'm') {
      this.showDeleteButton = false;
      this.showAddSignButtons = true;
      icon = 'assets/images/pin.png';
      dt = 'Now, Right Click';
    }
    let newMarker: any;
    if (lat != undefined && lng != undefined) {
      newMarker = marker([lat, lng], {
        title: dt,
        data: data,
        type: type,
        idx: index,
        icon: new L.DivIcon({
          className: 'loc_icon',
          html:
            '<img style="width: 40px !important;" src="' +
            icon +
            '"/></br>' +
            '<div style="width:120px;">' +
            dt +
            '</div>',
        }),
        draggable: draggable,
        clickable: false,
      });

      if (this.dataobjects?.role > 1) {
        newMarker.on('dragend', (event) => {
          const position = event.target.getLatLng();
          this.dropedlat = position.lat;
          this.dropedlng = position.lng;
          this.index = event.target.options.idx;
          const lastIndex: number = icon.lastIndexOf('/');

          let filename: string;
          if (lastIndex !== -1) {
            filename = icon.substring(lastIndex + 1);
            if (filename === 'start-position.png') {
              this.actionType = 'in';
              this.collectionName = 'emp_attendance';
            } else if (filename === 'logout.png') {
              this.actionType = 'out';
              this.collectionName = 'emp_attendance';
            } else if (filename === 'location.png') {
              this.actionType = 'ping';
              this.collectionName = 'device_ping';
            }
          }

          this.changedPing(
            this.actionType,
            event.target.options.idx,
            event.target.options.data,
            this.collectionName
          );

          const pinIcon = this.empPositionMarkers.find((marker) =>
            marker.options.icon.options.html.includes('pin.png')
          );

          if (pinIcon) {
            pinIcon.setOpacity(0); // Set opacity to 0 to hide the icon
          }
        });

        newMarker.on('click', (event) => {
          this.showDeleteButton = true;
          this.showAddSignButtons = false;
          const position = event.target.getLatLng();
          this.dropedlat = position.lat;
          this.dropedlng = position.lng;
          this.index = event.target.options.idx;
          this.iconData = event.target.options.data;
          // this.CurrentIndex = event.target.options.idx
          // Find and hide the "pin.png" icon
          const pinIcon: any = this.empPositionMarkers.find((marker) =>
            marker.options.icon.options.html.includes('pin.png')
          );
          if (pinIcon) {
            pinIcon.setOpacity(0); // Set opacity to 0 to hide the icon
          }
          const lastIndex: number = icon.lastIndexOf('/');
          let filename: string;
          if (lastIndex !== -1) {
            filename = icon.substring(lastIndex + 1);
            if (filename === 'start-position.png') {
              this.actionType = 'in';
              this.collectionName = 'emp_attendance';
            } else if (filename === 'logout.png') {
              this.actionType = 'out';
              this.collectionName = 'emp_attendance';
            } else if (filename === 'location.png') {
              this.actionType = 'ping';
              this.collectionName = 'device_ping';
            }
          }
        });
      }

      this.empPositionMarkers.push(newMarker);
      //IF NO DATA FROM DB THEN ONLY THIS CONDITIONS WILL BE RUN
      this.checkSignInAvailable(this.currentDate).then((result: any) => {
        if (result) {
          if (result?.data.map == undefined) {
            const newMarker1 = marker([lat, lng], {
              title: dt,
              type: type,
              icon: new L.DivIcon({
                className: 'loc_icon',
                html:
                  '<img style="width: 40px !important;" src="' +
                  icon +
                  '"/></br>' +
                  '<div style="width:120px;">' +
                  dt +
                  '</div>',
              }),
            });
            this.empPositionMarkers.push(newMarker1);
            this.combineMarkers();
            // Check if pin.png icon is present and remove it
            const pinIconIndex = this.empPositionMarkers.findIndex(
              (marker: any) =>
                marker.options.icon.options.html.includes('pin.png')
            );
            if (pinIconIndex !== -1) {
              this.empPositionMarkers.splice(pinIconIndex, 1);
            }
          }
        }
      });
    }
  }

  changedPing(action: any, index: string, data?: any, collectionName?: any) {
    var _id = localStorage.getItem('_id').replace(/[\[\]"]/g, '');
    const body: any = {
      _id: _id,
      index: index,
      lat: parseFloat(this.dropedlat),
      lng: parseFloat(this.dropedlng),
      action: action,
      emp_id: this._emp['_id'],
      data: data,
      dt: this.dt,
    };
    this.http.updatePing(body, collectionName).subscribe((result: any) => {
      this.snackbar.snackbarSuccess(
        `${this.helperService.capitalize(
          'Device ping'
        )}  has been Updated successfully`
      );
    });
    // this.cf.detectChanges()
  }
  deletePing(index: any, action: string, data: any) {
    let body: any = {
      // data:data,
      emp_id: this._emp['_id'],
      dt: this.dt,
    };
    //                    [object%20Object]/device_ping/1
    this.http
      .removeping(body, index, this.collectionName, action)
      .subscribe((result: any) => {
        this.checksignIn(false)
        this.snackbar.snackbarSuccess(
          `${this.helperService.capitalize(
            'Device ping'
          )}  has been removed successfully `
        );
      });
    // this.cf.detectChanges()
  }

  openPopUp(e) {
    e.preventDefault();
    e.stopPropagation();
    this.contextMenu.openMenu();
    // this.pingMenu.openMenu()
  }

  // Not used currently
  toggleTrackingMap(event: MatSlideToggleChange) {
    this.allMarkers = this.projectBoundaryMarkers;
    if (event.checked) {
      //view tracking info
      this.getEmpPingLocations(this._emp['_id']);
      this.mapZoom = 19;
    } else {
      //show last ping location
      this.loadEmployeeLastSeenInfo();
      this.mapZoom = 18;
    }
  }
  /**
   *
   *  Other methods
   *
   */
  showLastSeen() {
    if (this._emp && this._emp['last_seen']) {
      return `Last Seen at ${this.helperService.showDateTime(
        this._emp['last_seen']['dt']
      )}`;
    }
    return 'Till mobile app not installed';
  }

  combineMarkers() {
    if (this.isDefault === true) {
      const first = this.empPositionMarkers[0];
      const last = this.empPositionMarkers[this.empPositionMarkers?.length - 1];
      if (first != undefined || last != undefined) {
        this.allMarkers = _.concat(first, last);
      }
    } else {
      this.allMarkers = _.concat(
        this.projectBoundaryMarkers,
        this.empPositionMarkers
      );
    }
    this.cf.detectChanges();
  }

  ngOnDestroy(): void {
    this.mapSub.forEach((sub) => sub.unsubscribe());
    localStorage.removeItem('_id');
    localStorage.removeItem('device_id');
    sessionStorage.removeItem('geo_data');
  }

  setCurrentLocation(e) {
    this.currentLocation = e.latlng;
    this.contextMenuPosition.x = e.originalEvent.clientX + 'px';
    this.contextMenuPosition.y = e.originalEvent.clientY + 'px';
    if (this.dataobjects?.role > 1) {
      let data = this.empPositionMarkers.find((a) => a.options.type == 'm');

      if (data !== undefined) {
        this.empPositionMarkers.splice(
          this.empPositionMarkers.indexOf(data[0]),
          1
        );
      }
      this.addEmpLocMarker(
        this.dateString,
        this.currentLocation.lat,
        this.currentLocation.lng,
        'm',
        0,
        'm'
      );

      this.combineMarkers();
    }
  }

  Attendance: any;
  formName: any;
  hideSendButton: boolean = false;
  addSignInOut(type: string) {
    debugger
    if (type === 'ping') {
      this.Attendance = 'Ping';
      this.formName = 'ping';
      this.hideSendButton = true;
    } else {
      this.Attendance = 'Attendance';
      this.formName = 'attendance';
    }

    this.http
      .getLocationInfo(this.currentLocation.lat, this.currentLocation.lng)
      .subscribe((res) => {
        
        console.log(this.locations);

        let a = res['address'];
        let loc = {
          postalCode: a.postcode,
          street: a.street || a.road,
          isoCountryCode: a.country_code,
          country: a.country,
          administrativeArea: a.state,
          subAdministrativeArea: a.state_district,
          locality: a.city || a.county,
          subLocality: a.town || a.road,
        };
        let attendanceInfo = {
          lat: this.currentLocation.lat,
          lng: this.currentLocation.lng,
          type: type,
          emp_id: this._emp._id,
          loc: loc,
        };
        this.dialogService.openDialog(
          this.attendanceAddDialog,
          800,
          attendanceInfo
        );
      });
  }

  checksignIn(event: any) {
    debugger
    this.isDefault = event;
    //Show sign in and sign out is this.isDefault == true
    if (this.isDefault === true) {
      this.getEmpPingLocations(this._emp['_id'], this.currentDate);
    } else {
      this.GetPingData(this._emp['_id'], this.currentDate);
    }
    if (this.locations == undefined) {
    }
  }

  //GetPingData show only ping data
  GetPingData(eid, startDate?, endDate?) {
    startDate = startDate
      ? moment(startDate).startOf('day').format()
      : moment().startOf('day').format();
    endDate = endDate
      ? moment(endDate).endOf('day').format()
      : moment(startDate).endOf('day').format();
    var filter = {
      emp_id: eid,
      date_column: 'dt',
      start_date: startDate,
      end_date: endDate,
    };
    this.empPositionMarkers = [];
    this.locTimes = [];
    this.mapSub.push(
      this.http
        .getDocListBySearch('device_ping', filter)
        .subscribe((result: any) => {
          let SignInDate = this.locations[0]?.[this.locations[0]?.length - 1];
          let SignOutDate = this.locations[1]?.[this.locations[1]?.length - 1];
          if (result.data.length > 0) {
            // this.dt = result.data[0]?.["dt"]
            this.PingData.push(result.data[0]);
            result.data[0].ping.forEach((element: any, index: any) => {
              if (SignOutDate?.t != element?.t && SignInDate?.t != element?.t) {
                this.addEmpLocMarker(
                  element?.d ?? element?.t,
                  element?.p[0],
                  element?.p[1],
                  't',
                  1,
                  'a',
                  result.data[0]?.ping?.length,
                  element,
                  index
                );
              }
            });
            this.combineMarkers();
          }
        })
    );
  }
}
